import React from 'react';

function WorkGroupInfo(props) {
  return (
    <div className={props.hidden ? "hidden" : "showing"}>
      <h1>WorkGroupInfo</h1>

    </div>
  );
}

export default WorkGroupInfo;
